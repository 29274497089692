.mp-whitelisted-users-main {
  font-family: Montserrat; }
  .mp-whitelisted-users-main .mp-whitelisted-users-table-outer-div {
    border: 1px solid #c2c2c2;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    width: 452px; }
    .mp-whitelisted-users-main .mp-whitelisted-users-table-outer-div .mp-whitelisted-users-table-header-div {
      background-color: #c2c2c2;
      width: 450px;
      font-size: 14px;
      font-weight: 600;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
    .mp-whitelisted-users-main .mp-whitelisted-users-table-outer-div .mp-whitelisted-users-table-pagination-div {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 12px; }
    .mp-whitelisted-users-main .mp-whitelisted-users-table-outer-div .mp-whitelisted-users-table-entry-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px; }
    .mp-whitelisted-users-main .mp-whitelisted-users-table-outer-div .mp-whitelisted-users-table-entry-value-div {
      font-size: 12px; }
  .mp-whitelisted-users-main .mp-whitelisted-users-dialog-outer-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; }
